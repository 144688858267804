import styled from "styled-components";

export const tailwindClasses = {
  card: "pt-[16px] pr-[16px] pb-[24px] h-[500px] flex items-center justify-center",
  cardIcon:
    "absolute left-[0] right-[0] bottom-[-18px] rounded-[50%] w-[36px] h-[36px] m-auto bg-[#fff] shadow-[0 0 3px 0 #58A4AE] flex flex-center items-center justify-center",
  cardWrap: "relative",
  cardBody: "relative z-[2]",
  cardTitle: "text-[32px] text-[#272727] mb-[16px]",
};

export const Card = styled.div`
  padding: 48px 16px 48px;
  text-shadow: 1px 0 0 #58a4ae;
  color: #fff;
  overflow: hidden;
  position: relative;
  .card-heading {
    font-size: 42px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 8px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 290px;
      height: 4px;
      bottom: 0;
      background: #58a4ae;
    }
  }
  // &:after {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     background: rgba(0,0,0,0.3);
  //     z-index: 1;
  // }
  .card-content {
    font-size: 24px;
    text-transform: capitalize;
    letter-spacing: 2px;
  }
  .card-img {
    position: absolute;
    left: 0;
    top: -460px;
    width: 100%;
    min-height: 960px; // Remove this line after setting images
  }
`;
